import { call, put, takeEvery } from "redux-saga/effects";
import * as sliceMethods from "./slice";
import { commonService } from "./commonService";

function* fetchItemsSaga(action) {
  const payload = action.payload;
  yield put(sliceMethods.updateLoading(true));
  try {
    const response = yield commonService.getSearchMovie(payload);
    if (response?.status === 200 && response?.data?.Search?.length > 0) {
      yield put(sliceMethods.updateGetResultFilms([...response?.data?.Search]));
    }
    yield put(sliceMethods.updateLoading(false));
  } catch (error) {
    yield put(sliceMethods.updateLoading(false));
    console.log(error);
  }
}

function* fetchAllMovies(action) {
  const payload = action.payload;
  yield put(sliceMethods.updateLoading(true));
  try {
    let arr = [];
    for (const item of payload) {
      const response = yield call(commonService.getAllList, item.value);
      if (response?.data) {
        arr.push(response?.data);
      }
    }
    yield put(sliceMethods.updateCurrentPlayList(arr));
    yield put(sliceMethods.updateLoading(false));
  } catch (e) {
    yield put(sliceMethods.updateLoading(false));
    console.error("Error fetching movies:", e);
  }
}

function* watchItemsSaga() {
  yield takeEvery(sliceMethods.updateGetFilms, fetchItemsSaga);
  yield takeEvery(sliceMethods.updateGetList, fetchAllMovies);
}

export default watchItemsSaga;
