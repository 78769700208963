const RemoveCard = ({ filmData, addToList }) => {
  return (
    <div
      key={`imdbID-${filmData.imdbID}`}
      className="border h-96 w-40 overflow-hidden rounded shadow-md "
    >
      <div className="relative max-h-56 overflow-hidden">
        <button
          onClick={() => addToList(filmData.Title, filmData.imdbID)}
          className="absolute left-0 top-0 px-2 py-2.5 leading-none font-bold bg-black text-white"
        >
          -
        </button>
        <img src={filmData.Poster} alt="not found" className="w-full h-auto" />
      </div>
      {/* <div>Title: {filmData.Title}</div>
      <div>Type: {filmData.Type}</div>
      <div>Year: {filmData.Year}</div> */}
      <div className="p-3 space-y-2.5">
        <h4 className="leading-snug">{filmData.Title}</h4>
        <div className="text-sm text-gray-600">({filmData.Year})</div>
      </div>
    </div>
  );
};

export default RemoveCard;
