import { useDispatch, useSelector } from "react-redux";
import LogIn from "./LogIn";
import { updateShowLogIn } from "../reduxStore/slice";
import ViewList from "./ViewList";
import { useNavigate } from "react-router-dom";
import { Home, User } from "react-feather";
import { useEffect, useState } from "react";
import Loading from "./Loading";

const SideBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showLogIn = useSelector((state) => state.items.showLogin);
  const logInId = useSelector((state) => state.items.logIn);
  const viewList = useSelector((state) => state.items.viewList);
  const loading = useSelector((state) => state.items.loading);
  const [list, setNewList] = useState([]);
  const [filter, setNewFilter] = useState("");
  const filterData = (searchTerm, keys) => {
    return logInId?.value?.filter((item) =>
      keys.some((key) =>
        item[key]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };
  useEffect(() => {
    if (filter?.length > 0) {
      const words = filterData(filter, ["name"]);
      if (words?.length > 0) {
        setNewList(words);
      }
    }
  }, [filter]);
  const displayLogIn = () => {
    dispatch(updateShowLogIn(true));
  };
  const navigateList = (name) => {
    navigate(`/mylist/${name}`);
  };
  const setFilter = (text) => {
    setNewFilter(text);
  };
  const arrList = list?.length > 0 ? list : logInId?.value;
  return loading ? (
    <Loading />
  ) : (
    <div className="flex-none max-h-screen w-80 p-4 flex flex-col justify-between bg-gray-100 shadow-lg">
      <div className="flex-none">
        <h1 className="text-2xl font-bold text-red-600 mb-4">WatchLists</h1>
        <div className="mb-6 space-y-3">
          <input
            type="text"
            className="w-full p-2  border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
            onChange={(e) => setFilter(e.target.value)}
            value={filter}
          />
          <button
            className="flex items-center w-full p-3 bg-red-500 text-white rounded-md hover:bg-red-600"
            onClick={() => navigate("/")}
          >
            {" "}
            <Home className="mr-2" />
            Home
          </button>
        </div>
      </div>
      <div className="flex-1 mb-6 over">
        <h2 className="text-lg font-semibold text-gray-700 mb-3">My Lists</h2>
        <div className="max-h-96  overflow-y-auto">
          <ul className="space-y-2">
            {arrList?.map((item) => {
              return (
                <li key={item.name}>
                  <button
                    onClick={() => navigateList(item.name)}
                    className="w-full text-left p-2 hover:bg-gray-200 rounded-md border"
                  >
                    {item.name}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="flex-none flex items-center justify-between">
        <button
          onClick={() => displayLogIn()}
          className="flex items-center text-gray-700 hover:text-black"
        >
          <User className="mr-2" />
          {logInId?.name ? logInId?.name : "Log In"}
        </button>
      </div>
      {showLogIn && <LogIn />}
      {viewList && <ViewList />}
    </div>
  );
};

export default SideBar;
