import { createSlice } from "@reduxjs/toolkit";

const movieState = {
  getFilms: false,
  resultFilms: [],
  logIn: "",
  showLogin: false,
  viewList: false,
  viewListValue: false,
  getList: false,
  currentPlayList: [],
  loading: false,
};
export const itemsSlice = createSlice({
  name: "movieSlice",
  initialState: movieState, // Initial state is an empty array
  reducers: {
    updateGetFilms: (state, action) => {
      state.getFilms = action.payload; // Add item to the array
    },
    updateGetResultFilms: (state, action) => {
      state.resultFilms = action.payload;
    },
    updateLogin: (state, action) => {
      state.logIn = action.payload;
    },
    updateShowLogIn: (state, action) => {
      state.showLogin = action.payload;
    },
    updateViewList: (state, action) => {
      state.viewList = action.payload;
    },
    updateViewListValue: (state, action) => {
      state.viewListValue = action.payload;
    },
    updateGetList: (state, action) => {
      state.getList = action.payload;
    },
    updateCurrentPlayList: (state, action) => {
      state.currentPlayList = action.payload;
    },
    updateLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});
export const {
  updateGetFilms,
  updateGetResultFilms,
  updateLogin,
  updateShowLogIn,
  updateViewList,
  updateViewListValue,
  updateGetList,
  updateCurrentPlayList,
  updateLoading,
} = itemsSlice.actions;
export default itemsSlice.reducer;
