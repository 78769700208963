import ListBody from "../components/ListBody";
import SideBar from "../components/Sidebar";
const MyList = () => {
  return (
    <div>
      <div className="flex h-screen relative">
        <SideBar />
        <ListBody />
      </div>
    </div>
  );
};

export default MyList;
