import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import MyList from "./pages/MyList";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/mylist/:name" element={<MyList />} />
    </Routes>
  );
};

export default AppRoutes;
