import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateGetFilms,
  updateShowLogIn,
  updateViewList,
  updateViewListValue,
} from "../reduxStore/slice";
import Card from "./Card";

const Homebody = () => {
  const [textValue, setTextValue] = useState("");
  const films = useSelector((state) => state.items.resultFilms);
  const logInId = useSelector((state) => state.items.logIn);
  const dispatch = useDispatch();
  const fetchFilms = () => {
    dispatch(updateGetFilms(textValue));
  };
  const addToList = (key, value) => {
    if (logInId?.name) {
      dispatch(updateViewList(true));
      dispatch(updateViewListValue({ name: key, value: value }));
    } else {
      dispatch(updateShowLogIn(true));
    }
  };
  return (
    <div className="overflow-y-auto p-10 w-full">
      <div className="border border-red-300 rounded-md p-4 mb-6">
        <h1 className="text-2xl font-semibold">
          Welcome to <span className="text-red-500">Watchlists</span>
        </h1>
        <p className="text-gray-700 mt-2">
          Browse movies, add them to watchlists, and share them with friends.
        </p>
      </div>

      <div className="flex items-center mb-6">
        <input
          type="text"
          onChange={(e) => setTextValue(e.target.value)}
          value={textValue}
          className="flex-grow p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 mr-4"
        />
        <button
          onClick={() => fetchFilms()}
          className="p-2 bg-red-500 text-white rounded-md hover:bg-red-600"
        >
          SEARCH
        </button>
      </div>
      <div className="flex flex-wrap gap-10">
        {films?.map((i) => (
          <Card filmData={i} addToList={addToList} />
        ))}
      </div>
    </div>
  );
};

export default Homebody;
