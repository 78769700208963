import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateLogin, updateShowLogIn } from "../reduxStore/slice";
import { getFromLocalStorage, setToLocalStorage } from "../common";
import { useNavigate } from "react-router-dom";

const LogIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mail, setMail] = useState("");
  const close = () => {
    dispatch(updateShowLogIn(false));
    navigate("/");
  };
  const setLogin = () => {
    const userData = getFromLocalStorage("filmDB");
    const keys = userData && Object.keys(userData);
    if (keys && keys?.length > 0 && userData[mail]) {
      dispatch(updateLogin({ ...userData[mail] }));
    } else if (keys?.length > 0) {
      setToLocalStorage("filmDB", {
        ...userData,
        [mail]: { name: mail, value: [] },
      });
      dispatch(updateLogin({ name: mail, value: [] }));
    } else {
      setToLocalStorage("filmDB", { [mail]: { name: mail, value: [] } });
      dispatch(updateLogin({ name: mail, value: [] }));
    }
    close();
  };
  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60 z-40">
      <div className="absolute w-full max-w-96 max-h-96 top-20 left-1/2 -translate-x-1/2 bg-white rounded-md shadow-md overflow-hidden">
        <header className="flex justify-end p-1.5 bg-gray-200">
          <button
            onClick={() => close()}
            className="p-1.5 pt-0 leading-none text-white bg-red-500 rounded-sm hover:bg-red-700"
          >
            x
          </button>
        </header>
        <div className="flex flex-col gap-5 p-8">
          <input
            type="email"
            value={mail}
            placeholder="Email"
            onChange={(e) => setMail(e.target.value)}
            className="px-2.5 py-1.5 border rounded-md"
          />
          <button
            onClick={() => setLogin()}
            className="px-2.5 py-1.5 bg-green-500 rounded-md hover:bg-green-600 border-green-500 hover:border-green-600"
          >
            Log In
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
