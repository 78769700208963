import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateLogin, updateViewList } from "../reduxStore/slice";
import { getFromLocalStorage, setToLocalStorage } from "../common";
import { Trash2 } from "react-feather";
import _ from "lodash";

const ViewList = () => {
  const dispatch = useDispatch();
  const logIn = useSelector((state) => state.items.logIn);
  const [viewNew, setViewNew] = useState(false);
  const [list, setNewList] = useState("");
  const viewListValue = useSelector((state) => state.items.viewListValue);
  const dataOBj = _.cloneDeep(logIn);
  const addtoList = (name) => {
    dataOBj.value.forEach((i) => {
      if (i.name === name) {
        i.value = [...i.value, viewListValue];
      }
    });
    checkNew();
    close();
  };
  const open = () => {
    setViewNew(!viewNew);
  };
  const close = () => {
    dispatch(updateViewList(false));
  };
  const checkNew = () => {
    dispatch(updateLogin(dataOBj));
    const userData = getFromLocalStorage("filmDB");
    setToLocalStorage("filmDB", { ...userData, [logIn.name]: dataOBj });
  };
  const createNew = () => {
    if (list?.length > 0) {
      dataOBj.value = [
        ...dataOBj.value,
        { name: list, value: [viewListValue] },
      ];
      checkNew();
      close();
    }
  };
  const removeList = (name) => {
    dataOBj.value = dataOBj?.value?.filter((item) => item.name !== name);
    checkNew();
  };

  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60 z-40">
      <div className="absolute w-full max-w-96 max-h-96 top-20 left-1/2 -translate-x-1/2 bg-white rounded-md shadow-md overflow-hidden">
        <header className="flex justify-between p-1.5 bg-gray-200">
          <div>Add To Playlist</div>
          <button
            onClick={() => close()}
            className="p-1.5 pt-0 leading-none text-white bg-red-500 rounded-sm hover:bg-red-700"
          >
            x
          </button>
        </header>

        <div className="max-h-52 overflow-y-auto">
          <ul>
            {logIn?.value?.map((i) => {
              return (
                <li className="flex justify-between p-1">
                  <button onClick={() => addtoList(i.name)}>{i?.name} +</button>
                  <Trash2
                    className="mr-1"
                    onClick={() => removeList(i.name)}
                    size={"15px"}
                  />
                </li>
              );
            })}
          </ul>
        </div>
        <div className="flex flex-col border-t">
          {!viewNew && (
            <div className="p-1.5">
              <button
                onClick={() => open()}
                className="w-full px-2 py-1 mt-1 bg-green-500 rounded-md hover:bg-green-600 border-green-500 hover:border-green-600"
              >
                create new list +
              </button>
            </div>
          )}
          {viewNew && (
            <div className="flex flex-col p-2">
              <input
                type="text"
                value={list}
                onChange={(e) => setNewList(e.target.value)}
                className="px-2 py-1 border rounded-md"
                placeholder="Name"
              />
              <button
                onClick={() => createNew()}
                className="px-2 py-1 mt-1 bg-green-500 rounded-md hover:bg-green-600 border-green-500 hover:border-green-600"
              >
                Create New
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewList;
