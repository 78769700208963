import axios from "axios";
const movieClient = axios.create({
  baseURL: "https://www.omdbapi.com/",
  headers: {
    "Content-Type": "application/json",
  },
});
const apiKey = "27a943c5";
const getSearchMovie = async (payload) => {
  const response = await movieClient.get("", {
    params: {
      apikey: apiKey,
      s: payload,
    },
  });
  return response;
};
const getAllList = async (payload) => {
  const response = await movieClient.get("", {
    params: {
      apikey: apiKey,
      i: payload,
    },
  });
  return response;
};
export const commonService = {
  getSearchMovie,
  getAllList,
};
