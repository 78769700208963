import { useEffect } from "react";
import RemoveCard from "./RemoveCard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  updateCurrentPlayList,
  updateGetList,
  updateLogin,
} from "../reduxStore/slice";
import _ from "lodash";
import { getFromLocalStorage, setToLocalStorage } from "../common";

const ListBody = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { name } = useParams();
  const films = useSelector((state) => state.items.currentPlayList);
  const logInId = useSelector((state) => state.items.logIn);
  const dataOBj = _.cloneDeep(logInId);
  const newFilms = _.cloneDeep(films);
  useEffect(() => {
    if (!logInId) {
      navigate("/");
    }
    const listId = logInId?.value?.find((item) => item.name === name);
    if (listId?.value?.length > 0) {
      dispatch(updateGetList(listId?.value));
    } else if (listId?.value?.length === 0 && films?.length !== 0) {
      dispatch(updateCurrentPlayList([]));
    }
  }, [name]);
  const addToList = (title, id) => {
    dataOBj?.value?.forEach((item) => {
      if (item.name) {
        item.value = item.value.filter((i) => i.name !== title);
      }
    });
    const filtered = newFilms?.filter((item) => item.Title !== String(title));
    dispatch(updateCurrentPlayList(filtered));
    checkNew();
  };
  const checkNew = () => {
    dispatch(updateLogin(dataOBj));
    const userData = getFromLocalStorage("filmDB");
    setToLocalStorage("filmDB", { ...userData, [logInId.name]: dataOBj });
  };
  return (
    <div className="overflow-y-auto p-10 w-full">
      <div className="border border-red-300 flex justify-center items-center rounded-md p-4 mb-6">
        <span className="text-2xl font-semibold">{name}</span>
      </div>
      <div className="flex flex-wrap gap-10">
        {films?.map((i) => (
          <RemoveCard filmData={i} addToList={addToList} />
        ))}
      </div>
    </div>
  );
};

export default ListBody;
