const Loading = () => {
  return (
    <div className="w-full h-full  bg-black bg-opacity-75 z-50 absolute top-0 left-0 flex justify-center items-center">
      <div className="text-red-500 text-2xl font-semibold">
        Loading.............
      </div>
    </div>
  );
};

export default Loading;
