import { configureStore } from "@reduxjs/toolkit";
import itemsReducer from "./slice";
import createSagaMiddleware from "redux-saga";
import watchItemsSaga from "./saga";

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
  reducer: {
    items: itemsReducer, // Add the items reducer to the store
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
});
sagaMiddleware.run(watchItemsSaga);
