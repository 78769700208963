import Homebody from "../components/HomeBody";
import SideBar from "../components/Sidebar";

const Home = () => {
  return (
    <div className="flex h-screen relative">
      <SideBar />
      <Homebody />
    </div>
  );
};

export default Home;
